
import { Vue, Component } from 'vue-property-decorator'
import { BackgroundCheckStore } from '@/store'
import { BackgroundCheck } from '@/modules/models'
import { bgc_columns } from '@/modules/static'
import CustomTable from '@/components/CustomTable.vue'


@Component({
	components: {
		CustomTable
	}
})
export default class BackgroundCheckList extends Vue {
	columns = bgc_columns

	get backgroundChecks(): BackgroundCheck[]{
		return Object.values(BackgroundCheckStore.backgroundChecks)
	}

	created(){
		BackgroundCheckStore.loadBackgroundChecks()
	}
}
